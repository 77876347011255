<template>
  <help-page class="help-page" title="general.router-help-question">
    <el-card shadow="hover">
      <div class="title">
        {{ title }}
      </div>
      <hr class="split-line" />
      <div class="content">
        <div
          class="question-item"
          v-for="(item, index) in questionList"
          :key="index"
          @click="goToAnswerPage(item)"
        >
          {{ index + 1 }}.{{ item.title }}
        </div>
      </div>
    </el-card>
  </help-page>
</template>

<script setup>
import helpPage from '../help-page.vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'

import { ref } from 'vue'
const store = useStore()
const router = useRouter()
const route = useRoute()
const type = route.params.type
const title = route.params.title

const questionList = ref([])
const initData = async () => {
  const res = await store.dispatch('help/getQuestionList', type)
  questionList.value = res
}
const goToAnswerPage = (item) => {
  router.push(`/article/answer/${type}/${item.title}`)
}
initData()
</script>

<style scoped lang="scss">
.help-page {
  .title {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 18px;
  }
  :deep(.el-collapse-item__header) {
    font-weight: bold;
    font-size: 15px;
  }
  .split-line {
    margin-top: 20px;
    margin-bottom: 10px;
    color: #e9e9eb;
    background-color: #e9e9eb;
    height: 1px;
    border: none;
  }
  .content {
    .question-item {
      font-size: 14px;
      cursor: pointer;
      color: #285a9d;
    }
  }
}
</style>
